import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';

// import { Http, Response } from '@angular/http';
// import { Todo } from './todo';
// import { Observable } from 'rxjs/Observable';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ReturnsService {

  constructor(private http: HttpClient) { }
  public getAllReturns() {
    return this.http.get(`${API_URL}returns/`);
  }
  public getReturn(id) {
    return this.http.get(`${API_URL}returns/view/${id}/`);
  }
  public getReturnsCount(store_id) {
    return this.http.post(`${API_URL}returns-count/`, { "store_id": store_id });
  }
  public getTodaysReturnsCount(store_id) {
    return this.http.get(`${API_URL}todays-sales/${store_id}/`);
  }
  public getReturns(event, store_id, to?, from?, order_by?, query?) {
    return this.http.post(`${API_URL}get-returns/`, { "event": event, "store_id": store_id, "to": to, "from": from, "order_by": order_by, "query": query });
  }
  public getReturnsColumns() {
    return this.http.get(`${environment.apiUrl}returns-columns/`);
  }
  public setReturnsColumnDisplay(item_id, state) {
    return this.http.post(`${API_URL}set-returns-columns/`, { "item_id": item_id, "state": state });
  }
  public getReturnsToday(date, store_id, event) {
    return this.http.post(`${API_URL}get-returns-today/${store_id}/`, { "date": date, "event": event });
  }
  public getReturnsCountToday(date, store_id) {
    return this.http.post(`${API_URL}todays-sales/${store_id}/`, { "date": date });
  }
  public getReport(date, store_id) {
    return this.http.post(`${API_URL}report/${store_id}/`, { "date": date });
  }
  public correctMargins() {
    return this.http.get(`${API_URL}correct-returns-margin/`);
  }
  public resend(content, data) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options = { headers: headers };
    let data_to_send = [];
    data_to_send.push(data);
    data_to_send.push(JSON.stringify(content));

    return this.http.post(`${API_URL}resend-email/${data["customer"]["email_id"]}/`, JSON.stringify(content), options);
    // return this.http.post(`${API_URL}resend-email/`, content)
  }
}